import "./CalendarEvent.css";
import { MdOutlinePaid } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaChild, FaGlassCheers } from "react-icons/fa";
import { useMemo } from "react";

import { FaBirthdayCake } from "react-icons/fa";
import { GiWoodenChair } from "react-icons/gi";

const CalendarEvent = ({
  cognome,
  ora,
  event_id,
  showModal,
  paid,
  da_chiamare,
  animazione,
  evento,
}) => {
  const setClasses = useMemo(() => {
    let base = "calendar-event";
    if (evento.tipo === "birthday") {
      const isCall = (name) => {
        return evento?.nome_tipo_compleanno?.toLowerCase()?.includes(name);
      };

      base += ` orange`;

      if (isCall("pranzo")) {
        base += ` pranzo`;
      }
      if (isCall("happy bowling pn")) {
        base += ` happy-pn`;
      }
      if (isCall("happy bowling")) {
        base += ` purple`;
      }
      if (isCall("laser tag")) {
        base += ` laser`;
      }
      if (isCall("vr arena")) {
        base += ` vr`;
      }
    } else if (evento.tipo === "evento") base = "calendar-event blue";

    return base;
  }, [evento.tipo, evento.tipo_compleanno]);

  return (
    <div className={setClasses} onClick={() => showModal()}>
      <div className="calendar-event-info">
        <p>{`${event_id}`}</p>
        <p className="calendar-event-time">{`${ora}`}</p>

        {paid && (
          <MdOutlinePaid
            style={{
              fontSize: "1rem",
              height: "1rem",
              width: "1rem",
            }}
          />
        )}
        {da_chiamare === true && (
          <BsFillTelephoneFill
            style={{
              fontSize: "1rem",
              height: "1rem",
              width: "1rem",
            }}
          />
        )}

        {animazione === true && (
          <FaChild
            style={{
              fontSize: "1rem",
              height: "1rem",
              width: "1rem",
            }}
          />
        )}
        {evento?.dolce === true && (
          <FaBirthdayCake
            style={{
              fontSize: "1rem",
              height: "1rem",
              width: "1rem",
            }}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          gap: "13px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>{`${cognome}`}</p>

        <div
          style={{
            whiteSpace: "nowrap",

            display: "flex",
          }}
        >
          <div>
            <FaGlassCheers />
            {evento.numero_aperitivi ?? 0}
          </div>
          <div>
            <div>
              <GiWoodenChair />
              {evento.numero_invitati}
            </div>
            {evento.mangia_prima && <p>MP</p>}
          </div>
        </div>
      </div>

      {evento.nome_tipo_compleanno && (
        <p className="tipo-compleanno">{`${evento.nome_tipo_compleanno}`}</p>
      )}
    </div>
  );
};

export default CalendarEvent;
