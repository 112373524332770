import { useMemo, useContext } from "react";
import "./CalendarDay.css";
import CalendarEvent from "../views/CalendarEvent";
import { IoMdAddCircle } from "react-icons/io";

import { GiKnifeFork } from "react-icons/gi";
import { GiWoodenChair } from "react-icons/gi";
import { FaGlassCheers } from "react-icons/fa";
import { UserContext } from "../context/UserContext";

const CalendarDay = ({
  screenWidth,
  name,
  selfDate,
  dayEvents,
  showCalendarModal,
  showNewBookingModal,
  showAlertLimtModal,
}) => {
  const [user] = useContext(UserContext);

  const tots = useMemo(() => {
    let totale = 0;
    let totale_bar = 0;
    let tot_happy = 0;
    let tot_bday = 0;
    let tot_vr_laser = 0;
    // let tot_eventi = 0
    let tot_ape = 0;
    let tot_pranzo = 0;

    dayEvents.forEach((evento) => {
      var isCall = (name) => {
        return evento?.nome_tipo_compleanno?.toLowerCase()?.includes(name);
      };

      if (evento.tipo === "birthday") {
        tot_ape += evento.numero_aperitivi ?? 0;
        totale += evento.numero_invitati;
        totale_bar += evento.numero_invitati;
        totale_bar += evento.numero_aperitivi ?? 0;
      }

      if (evento.tipo === "birthday" && isCall("pranzo")) {
        tot_pranzo += evento.numero_invitati;
      }

      if (evento.tipo === "birthday" && isCall("happy bowling")) {
        tot_happy += evento.numero_invitati;
      } else if (
        evento.tipo === "birthday" &&
        (isCall("vr arena") || isCall("laser tag"))
      ) {
        tot_vr_laser += evento.numero_invitati;
      } else if (
        evento.tipo === "birthday" &&
        !isCall("happy bowling") &&
        !isCall("vr arena") &&
        !isCall("laser tag")
      ) {
        tot_bday += evento.numero_invitati;
      }
      // else if (
      // 	evento.tipo === 'evento'
      // ){
      // 	tot_eventi += evento.numero_invitati
      // }
    });

    return {
      totale: totale,
      tot_bday: tot_bday,
      tot_happy: tot_happy,
      // tot_eventi:tot_eventi,
      tot_ape: tot_ape,
      tot_pranzo: tot_pranzo,
      totale_bar: totale_bar,
      tot_vr_laser: tot_vr_laser,
    };
  }, [dayEvents]);

  const renderHeader = useMemo(() => {
    return (
      <div className="day-header">
        <div>
          {user.solo_lettura === false && (
            <IoMdAddCircle
              onClick={() => {
                if (tots.totale_bar >= 130) {
                  showAlertLimtModal();
                } else {
                  showNewBookingModal("show");
                }
              }}
            />
          )}

          <label>{`${name} ${selfDate.getDate()}/${selfDate.getMonth() + 1}`}</label>

          <span style={{ color: "black" }}>
            {tots.tot_pranzo}

            <GiKnifeFork />
          </span>
        </div>
        <p className="totale-bambini">
          <span>{tots.tot_bday}</span>+<span>{tots.tot_happy}</span>+
          <span>{tots.tot_vr_laser}</span>+
          <span>
            {tots.tot_ape}
            <FaGlassCheers />
          </span>
          =<span>{tots.totale_bar}</span>
          <GiWoodenChair />
        </p>
      </div>
    );
  }, [screenWidth, name, selfDate, tots]);

  return (
    <div className="calendar-day">
      {renderHeader}

      <div className="calendar-event-container">
        {dayEvents.map((evento, index) => {
          return (
            <CalendarEvent
              key={index}
              cognome={evento.cognome}
              ora={`${evento.ora.split(":")[0]}:${evento.ora.split(":")[1]} `}
              event_id={evento.id}
              showModal={() => showCalendarModal(evento.id, evento.tipo)}
              paid={evento.acconto_pagato}
              da_chiamare={evento.da_chiamare}
              animazione={evento.animazione ?? null}
              evento={evento}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CalendarDay;
